<template>
    <div style="background-color: #d3d3d3; font-size: larger; font-weight: normal;padding: 4px">
      Closed until May 7, 2025
    </div>
<!--  <div>-->
<!--  </div>-->
</template>

<script>
    export default {
        name: "message"
    }
</script>
